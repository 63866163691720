// Footer

footer {
	padding-top: 135px;
	padding-bottom: 60px;
background: @gold;
color: #fff;
a {
	color: #fff;
	text-decoration: underline;
}

}
.newsletter {
	    margin-bottom: -70px;
	.col-md-5 {
				-webkit-box-shadow: 0px 40px 65px 14px rgba(0,0,0,0.11);
-moz-box-shadow: 0px 40px 65px 14px rgba(0,0,0,0.11);
box-shadow: 0px 40px 65px 14px rgba(0,0,0,0.11);
		background: #fff;
		color: @gold;
		font-family: 'Didot', serif;
		font-size: 28px;
		.content {
			.inscription {
				overflow: hidden;
				 max-height: 0;
				 -webkit-transition: all 0.3s ease-in-out;
				   -moz-transition: all 0.3s ease-in-out;
				   -o-transition: all 0.3s ease-in-out;
				   transition: all 0.3s ease-in-out;
				font-family: 'Lato', sans-serif;
				label {
					font-size: 16px;
				}
						.btn.btn-primary {
			background-color: @gold;
			color: #fff;
			border: 2px solid #fff;
			border-radius: 100px; 
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 12px;
			    padding: 15px 27px;
			    -webkit-transition: all 0.1s ease-in-out;
			      -moz-transition: all 0.1s ease-in-out;
			      -o-transition: all 0.1s ease-in-out;
			      transition: all 0.1s ease-in-out;
			      font-weight: 600;
		}
		.btn.btn-primary:hover {
				background-color: #fff;
			color: @gold;
			border: 2px solid @gold;
		}
			}
			padding:30px;
		}
	}
	.col-md-3 {
				-webkit-box-shadow: 0px 40px 65px 14px rgba(0,0,0,0.11);
-moz-box-shadow: 0px 40px 65px 14px rgba(0,0,0,0.11);
box-shadow: 0px 40px 65px 14px rgba(0,0,0,0.11);
		background: @brun;
		color: #fff;
		font-size: 24px;
	}
	.cta-newsletter {
		padding:30px;
		cursor: pointer;
		line-height: 1.7;
		&:after {
			content:">";
			    position: absolute;
			    top: 50%;
			    right: 30px;
			    -webkit-transform: translate(0%,-50%);
			    -ms-transform: translate(0%,-50%);
			    transform: translate(0%,-50%);
		}
	}
}