.clear {
	clear: both;
}

// Content Body
.content-body {
	position: relative;
	max-width: 900px;
	margin: 0 auto 24px;
	padding-bottom: 160px;

	// All
	& > * {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}

	// Margin
	p+p, p+ul, p+ol, p+blockquote, p+hr {
		margin-top: @line-height-computed;
	}

	ul+p, ul+ul, ul+ol, ul+blockquote, ul+hr, ol+p, ol+ul, ol+ol, ol+blockquote, ol+hr {
		margin-top: @line-height-computed;
	}

	hr+p, hr+ul, hr+ol, hr+blockquote, hr+hr {
		margin-top: @line-height-computed;
	}
	
	// Aside
	& > aside {
		max-width: 900px;
	}

	// Colonnes
	& > .row {
		margin-bottom: @line-height-computed;

		 .col-md-6:first-child {
			padding-left: 0;
		}

		 .col-md-6:last-child {
			padding-right: 0;
		}
	}
}

// Lead
p.lead {
	font-size: 1.33em;
	line-height: 1.5em;
	color: @brand-primary;
	font-weight: 300;
	margin-bottom: @line-height-computed;
}

// Evidence
p.evidence, div.evidence {
	color: #fff;
	background-color: @brand-primary;
	padding: @line-height-computed*2;
	margin-bottom: @line-height-computed;

	a {
		color: #fff;
	}

	a.btn {
		background-color: #fff;
		color: @brand-primary;

		&:hover {
			background-color: darken(#fff, 10%);
		}
	}
}

// Img
img {
	max-width: 100%;
	height: auto;

	&[style*="left"] {
		margin-right: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	&[style*="right"] {
		margin-left: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

// Table
table {
	border: 1px solid #d8d8d8;
	margin-bottom: @line-height-computed;
	width: 100%;

	th {
		background-color: @brand-primary;
		color: #fff;
		text-transform: uppercase;
		font-weight: 400;
	}

	td {
		background-color: #f0f0f0;
	}

	tr.even td {
		background-color: darken(#f0f0f0, 5%);
	}

	td, th {
		padding: @line-height-computed/2 @line-height-computed;
	}
}

// Iframe responsive
.iframe-embed {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	margin-bottom: @line-height-computed;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Boutons
.btn1 {
	background-color: @brand-primary;
	color: #fff;
}

.btn2 {
	background-color: @brand-primary;
	color: #fff;
}

.form-item {
	label.control-label {
		display: block;
	}
	.field-prefix {
		display: inline-block;
		width: 9%;
		& + input {
			display: inline-block;
			width: 90%;
		}
	}
}