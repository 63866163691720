// Header


header {
	z-index: 888!important;
.navbar-brand {
	img {
		width:250px;	
	}
}

}
.navbar-default .navbar-nav>li>a {
	text-transform: uppercase;
	color: #000;
	font-weight: 700;
	font-size: 13px;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
	background-color: transparent;
	color: @gold;
}
.navbar-default .navbar-nav a:hover {
	color: @gold!important;
}
.slider-bg {
	background-position: center center;
	background-repeat: no-repeat;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	  min-height: 600px;
}
.unslider-wrap li,.unslider-wrap {
  min-height: 600px;
}
.front{
	#leaderboard {
	position: relative;
	}
	.slider-content {
		z-index: 99;
		position: absolute;
		right:0;
		top:0;
		min-height: 600px;
		    text-align: right;
		background-color: rgba(192,162,91,0.9);
		width: 50vw;
		.content {
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    width: 50%;
			    height: auto;
			    -webkit-transform: translate(-50%,-50%);
			    -ms-transform: translate(-50%,-50%);
			    transform: translate(-50%,-50%);
		}
		h1 {
			color: #fff;
			font-size: 52px;
			padding-bottom: 30px;
		}
		.btn.btn-primary {
			background-color: transparent;
			color: #fff;
			border: 2px solid #fff;
			border-radius: 100px; 
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 12px;
			    padding: 20px 27px;
			    -webkit-transition: all 0.1s ease-in-out;
			      -moz-transition: all 0.1s ease-in-out;
			      -o-transition: all 0.1s ease-in-out;
			      transition: all 0.1s ease-in-out;
			      font-weight: 600;
		}
		.btn.btn-primary:hover {
			background: #fff;
			color: @gold;
		}
	}
}
.navbar-nav {
	float: right;
}
.top-nav {
	float: right;
	> ul {
		> li {
			float: left;
			&:last-child {
				.dropdown-menu {
					left: auto;
					right: 0;
				}
			}
		}
	}
	a {
		text-transform: none!important;
		background-color: transparent !important;
		color: #737373!important;
		padding-bottom: 5px!important;
		padding-top: 15px;
		&:hover {
			color: @gold !important;
		}
	}
}
header .navbar-header #navbar {
	padding-bottom: 30px;

}
header {
	padding-top: 13px;
	padding-bottom: 15px;
-webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header.scrolled {
		padding-top: 0px;
	padding-bottom: 0px;
}
@media (min-width: 992px){
.navbar-toggle {
    display: none;
}
}
@media (max-width: 992px){
	.front #leaderboard, .not-front #main {
    margin-top: 78px!important;
}
header #navbar {
    display: none!important;
}
.navbar-toggle {
    display: block!important;
}
.navbar-header {
float: none!important;
}
header .navbar-brand img {
    width: 145px!important;
}
}