// Bootstrap library.
@import "bootstrap";

// Base-theme overrides.
@import "overrides";

// Theme specific.
@import "header";
@import "../node_modules/swiper/swiper-bundle.css";
@import "home";
@import "content";
@import "footer";
@import "https://fonts.googleapis.com/css?family=Lato|Open+Sans";
@font-face {
  font-family: "Didot";
  src: url("Didot.eot");
  src: url("Didot.eot?#iefix") format("embedded-opentype"),
    url("Didot.woff") format("woff"), url("Didot.ttf") format("truetype"),
    url("Didot.svg#Didot") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Didot Italic";
  src: url("Didot-Italic.eot");
  src: url("Didot-Italic.eot?#iefix") format("embedded-opentype"),
    url("Didot-Italic.woff") format("woff"),
    url("Didot-Italic.ttf") format("truetype"),
    url("Didot-Italic.svg#DidotItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Didot Bold";
  src: url("Didot-Bold.eot");
  src: url("Didot-Bold.eot?#iefix") format("embedded-opentype"),
    url("Didot-Bold.woff") format("woff"),
    url("Didot-Bold.ttf") format("truetype"),
    url("Didot-Bold.svg#DidotBold") format("svg");
  font-weight: bold;
  font-style: normal;
}
// colors
@gold: #c2a662;
@gc: #f7f7f7;
@txt: #201c20;
@brun: #292624;

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

h1,
h2,
h3,
h4 {
  font-family: "Didot", serif;
  -webkit-font-smoothing: subpixel-antialiased;
}
h1 {
  letter-spacing: 1px;
  line-height: 1.2;
}
body {
  font-family: "Lato", sans-serif;
  background-color: @gc;
  overflow-x: hidden;
}

// unslider

.unslider {
  overflow: auto;
  margin: 0;
  padding: 0;
}
.unslider-wrap {
  position: relative;
}
.unslider-wrap.unslider-carousel > li {
  float: left;
}
.unslider-vertical > ul {
  height: 100%;
}
.unslider-vertical li {
  float: none;
  width: 100%;
}
.unslider-fade {
  position: relative;
}
.unslider-fade .unslider-wrap li {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 8;
}
.unslider-fade .unslider-wrap li.unslider-active {
  z-index: 10;
}
.unslider li,
.unslider ol,
.unslider ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border: none;
}
.unslider-arrow {
  position: absolute;
  left: 20px;
  z-index: 2;
  cursor: pointer;
}
.unslider-arrow.next {
  left: auto;
  right: 20px;
}

.unslider-nav ol {
  list-style: none;
  text-align: center;
}
.unslider-nav ol li {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 4px;
  background: transparent;
  border-radius: 5px;
  overflow: hidden;
  text-indent: -999em;
  border: 2px solid #fff;
  cursor: pointer;
}
.unslider-nav ol li.unslider-active {
  background: #fff;
  cursor: default;
}
.unslider-nav {
  position: absolute;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  right: 40px;
  top: 50%;
  z-index: 120;
}

.hp-cta {
  margin-top: 110px;
  margin-bottom: 80px;

  .content {
    .bloc {
      padding: 40px 30px;
      height: 100%;
      background-color: #fff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      color: #8e7745;
      font-size: 16px;
      line-height: 1.7;

      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #333;
      }

      h2 {
        font-size: 21px;
        text-transform: uppercase;
      }

      h6 {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.6;
      }

      a {
        color: #8e7745;
        text-decoration: underline;
      }
    }

    .swiper-container {
      padding-bottom: 30px;
      // width: calc(~"100vw - ((100vw - 100%) / 2)");
      width: 100%;
      height: auto;

      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
    }

    .swiper-slide {
      width: 270px;
      height: auto;

      @media (max-width: @screen-xs-max) {
        width: 100% !important;
      }

      &.half {
        width: 540px;
      }

      &.full {
        width: 100%;
      }
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 0;
    }
    .swiper-pagination-bullet-active {
      background: #8e7745;
    }
  }
}
.hp-content {
  overflow: hidden;
  background-color: #fff;
  position: relative;
  p {
    color: #777777;
    line-height: 2;
    padding-bottom: 25px;
    font-size: 16px;
  }
  .content {
    margin-top: 105px;
    margin-bottom: 110px;
    z-index: 2;
    h2 {
      font-size: 43px;
      line-height: 1.4;
      padding-bottom: 110px;
    }
  }
  .content * {
    z-index: 99;
  }
  .illu {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    right: 0;
    height: auto;
    width: 48%;
  }
}
.front #actualite {
  margin-top: 110px;
}
#actualite {
  p {
    line-height: 2;
  }

  margin-bottom: 110px;
  .top-img {
    height: 290px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .col-md-4 {
    padding: 0;
  }
  h2 {
    font-size: 40px;
    margin-bottom: 45px;
  }
  a {
    color: #a8a0a8;
    text-decoration: underline;
    display: block;
    padding-top: 30px;
  }
  h3 {
    margin-bottom: 20px;
    margin-top: 25px;
  }
}
.url-textfield {
  display: none;
}
.content-body > * {
  max-width: initial;
}
.content-body {
  padding-bottom: 30px;
  h2 {
    line-height: 1.5;
    font-size: 36px;
    margin-bottom: 45px;
  }
  p,
  li {
    text-align: justify;
    font-size: 15px;
    line-height: 1.7;
    color: #777777;
  }
  img[style*="right"] {
    margin-left: 70px;
    margin-bottom: 70px;
  }
  div.evidence,
  p.evidence {
    color: #777777;
    background-color: #fff;
    padding: 40px;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 1.7;
  }
  div.evidence {
    padding-top: 140px;
    padding-bottom: 140px;
    position: relative;
    z-index: -1;
    &:before {
      content: "";
      background-color: #fff;
      width: 100%;
      top: 0;
      left: -99%;
      height: 100%;
      position: absolute;
    }
    &:after {
      content: "";
      background-color: #fff;
      width: 100%;
      top: 0;
      right: -99%;
      height: 100%;
      position: absolute;
    }
  }
}
.breadcrumb {
  display: none;
}
.not-front {
  h1 {
    background-color: @gold;
    color: #fff;
    font-size: 52px;
    text-align: center;
    padding-top: 65px;
    padding-bottom: 65px;
    margin-bottom: 50px;
  }
}
body form.webform-client-form {
  margin: 60px auto 0;
  max-width: 870px;
}

body form.webform-client-form .webform-progressbar,
body.page-node-done .webform-progressbar {
  display: none;
}

body form.webform-client-form div.webform-component {
  margin: 0 0 30px;
  width: 100%;
}

body form.webform-client-form div.webform-component.webform-component-display {
  margin: 0;
}

@media (min-width: 1199px) {
  body form.webform-client-form div.webform-component.col-md-6 {
    width: 50%;
  }
}

body form.webform-client-form h3 {
  margin: 0 0 45px;
  color: #0071b9;
  font-size: 24px;
  line-height: 1.1;
}

body form.webform-client-form h4 {
  margin: 0 0 15px;
  color: #736868;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.42857143;
}

body form.webform-client-form label {
  margin: 0 0 15px;
}

body form.webform-client-form .radio,
body form.webform-client-form .checkbox {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 10px;
  width: calc(50% - 2px);
}

@media (max-width: 767px) {
  body form.webform-client-form .radio,
  body form.webform-client-form .checkbox {
    width: 100%;
  }
}

body form.webform-client-form .radio label,
body form.webform-client-form .checkbox label {
  margin: 0 !important;
}

body form.webform-client-form input[type="radio"],
body form.webform-client-form input[type="checkbox"] {
  /*    margin: 5px 0 0;*/
}

body form.webform-client-form .inline-choice .radio,
body form.webform-client-form .inline-choice .checkbox {
  padding: 0 10px;
  width: auto;
}

@media (max-width: 767px) {
  body form.webform-client-form .inline-choice .radio,
  body form.webform-client-form .inline-choice .checkbox {
    width: 100%;
  }
}

body form.webform-client-form fieldset {
  margin: 0 0 30px;
}

body
  form.webform-client-form
  fieldset
  div.webform-component:not(.webform-component-display) {
  margin-bottom: 15px;
}

body form.webform-client-form fieldset div.webform-component:nth-child(even) {
  // padding: 10px 0;
}

body form.webform-client-form fieldset div.webform-component > label {
  margin-bottom: 5px;
  padding: 0 10px;
}

body form.webform-client-form .webform-next {
  background-color: #80b229;
  color: #fff;
}

body form.webform-client-form .webform-next:hover {
  background-color: #53741b;
}

body form.webform-client-form .webform-previous {
  background-color: #0071b9;
  color: #fff;
}

body form.webform-client-form .webform-previous:hover {
  background-color: #00426d;
}

@media (max-width: 767px) {
  body form.webform-client-form .webform-previous {
    display: block;
    margin-bottom: 5px;
  }
}

body form.webform-client-form .description {
  // font-weight: 700;
}
fieldset legend.panel-heading {
  margin-bottom: 26px;
}
.inscription form.webform-client-form {
  margin-top: 0;
}
.webform-submit.btn.btn-primary {
  background-color: #c2a662;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 100px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  padding: 15px 27px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  font-weight: 600;
}
.webform-submit.btn.btn-primary:hover {
  background-color: #fff;
  color: #c2a662;
  border: 2px solid #c2a662;
}
select.day {
  width: 70px;
  float: left;
}
select.month {
  width: 70px;
  float: left;
}
select.year {
  width: 150px;
  float: left;
}
.not-front .webform-client-form {
  margin-bottom: 40px;
}
.not-front #main {
  margin-top: 119px;
}
.front #leaderboard {
  margin-top: 119px;
}
@media (max-width: 1200px) {
  .newsletter .col-md-5 {
    line-height: 1.6;
    font-size: 25px !important;
  }
  .front .slider-content h1 {
    font-size: 38px !important;
  }
}
@media (max-width: 992px) {
  .front .slider-content h1 {
    font-size: 29px !important;
  }
}
@media (max-width: 768px) {
  .front .slider-content {
    width: 100% !important;
  }
  .front .slider-content .content {
    text-align: center !important;
  }
  .hp-content .illu {
    position: relative;
    top: inherit;
    -webkit-transform: translate(0, 0%);
    -ms-transform: translate(0, 0%);
    transform: translate(0, 0%);

    width: 100%;
  }
  #actualite .views-row {
    padding-bottom: 40px;
  }
  .hp-content .content h2 {
    font-size: 29px;
    text-align: center;
    line-height: 1.4;
    padding-bottom: 40px;
  }
  .front .slider-content .content {
    width: 90%;
  }
  .unslider-nav {
    display: none;
  }
  footer * {
    text-align: center !important;
  }
}
.open-menu .mobile-nav {
  display: block;
}
body.open-menu {
  overflow: hidden;
}
.mobile-nav {
  position: fixed;
  padding-top: 70px;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow-x: hidden;

  width: 100%;
  z-index: -1;
  height: auto;
  min-height: 100vh;
  background-color: rgba(192, 162, 91, 0.9);
  li {
    float: none;
  }
  .navbar-nav,
  .topmenu {
    float: none;
    text-align: center;
    a:hover,
    a:focus {
      color: #fff !important;
      background-color: rgba(0, 0, 0, 0.3) !important;
    }
    .dropdown-menu {
      position: relative !important;
      margin: 0 !important;
      background-color: #fff !important;
      border-radius: 0 !important;
      float: none !important;
      color: #000 !important;
      padding: 0 !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      border: 0 !important;
      text-align: center;
      a {
        padding: 10px 15px !important;
      }
    }
    .dropdown-menu > li > a {
      color: #000 !important;
    }
    .navbar-nav > .active > a {
      color: #fff !important;
    }
  }
  .topmenu {
    li {
      a {
        color: #000 !important;
      }
      &:hover,
      &.active {
        > a {
          color: #fff !important;
        }
      }
    }
  }
}
.actu img {
  width: 100%;
}
.row.actu {
  padding-bottom: 40px;
  padding-top: 25px;
  border-bottom: 1px solid #ccc;
}
.actu-illu {
  display: block;
  max-width: 100%;
  padding-bottom: 30px;
}
.actu-container {
  padding-bottom: 40px;
}
p.lead {
  font-size: 17px;
  font-weight: 600;
}
a {
  color: @gold;
}
.btn.btn-default.btn1,
.btn.btn-default.btn2 {
  background-color: #c2a662;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 15px 27px;
  transition: all 0.1s ease-in-out;
  font-weight: 600;
}
.btn.btn-default.btn1:hover,
.btn.btn-default.btn2:hover {
  background-color: #fff;
  color: #c2a662;
  border: 2px solid #c2a662;
}
table th {
  background-color: @gold;
}

.menu > li:nth-child(1) > ul.dropdown-menu {
  position: absolute;
  top: 100%;
  /*  width: 50%;*/
  left: 50%;

  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  float: none;
  margin: 0;
  padding: 0;
  min-width: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 20px;
}
.menu > li:nth-child(1) > ul.dropdown-menu > li {
  float: left;
  width: 50%;
  @media (max-width: @screen-sm-max) {
    float: none;
    width: 100%;
  }
}

/*.menu>li>ul.dropdown-menu {
display: block;
}*/
/*.open>.dropdown-menu {
    display: block;
}*/
.menu > li > ul.dropdown-menu > li > ul.dropdown-menu {
  display: block;
  position: relative;
  top: auto;
  left: auto;
  border: 0;
  padding: 10px;
  padding-top: 0;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
/*.expanded.dropdown.open .dropdown-menu .expanded.dropdown {
	width: 33.333%;
}*/
.menu > li:nth-child(1) {
  position: static;
}
.menu > li:nth-child(1) .leaf a {
  //font-weight: 800!important;
}
.menu > li:nth-child(1) .expanded .leaf a {
  font-weight: 400 !important;
}
.dropdown .dropdown a.dropdown-toggle {
  //font-weight: 800;

  > .caret {
    display: none;
  }

  &.active:hover {
    color: #fff !important;
  }
}
.dropdown-menu a {
  &.active:hover {
    color: #fff !important;
  }
}
.dropdown-menu .dropdown-menu a {
  &.active:hover {
    color: #fff !important;
  }
}
.webform-confirmation {
  margin-bottom: 80px;
}
.evidence.or {
  color: #fff;
  background-color: rgba(192, 162, 91, 1);
  padding: 3.42rem;
  margin-bottom: 1.71rem;
}
div.evidence.or a,
p.evidence.or a {
  color: #fff;
}
.page-user {
  main {
    form {
      &:extend(.container);
      padding-top: 50px;
    }
  }
}
.profile {
  &:extend(.container);
  padding-top: 50px;
}
.inscription {
  form.webform-client-form {
    .checkbox {
      width: 100%;
      label {
        font-size: 1rem !important;
        color: #000;
      }
    }
    div.webform-component {
      margin-bottom: 10px;
    }
    .webform-component--mentions {
      p {
        font-size: 1rem !important;
      }
    }
  }
  form.webform-client-form .checkbox {
    margin-bottom: 0;
  }
}
.alert-cookie {
  display: none;
  background-color: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  color: #fff;
  z-index: 99;
  .alert-cookie-box {
    display: flex;
  }
  a {
    color: #fff;
    text-decoration: underline;
    &.accept {
      display: inline-block;
      margin-left: 20px;
      padding: 10px;
      background-color: #fff;
      color: #000;
      text-decoration: none;
      border-radius: 100%;
    }
  }
}
